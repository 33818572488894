export enum ButtonMsg {
    OK = 'Buttons.OK',
    CANCEL = 'Buttons.Cancel',
    YES = 'Buttons.Yes',
    NO = 'Buttons.No',
    CLOSE = 'Buttons.Close',
    CONFIRM = 'Buttons.Confirm',
    SAVE = 'Buttons.Save',
    CLEAR_SELECTION = 'Buttons.ClearSelection',
    SAVE_AS = 'Buttons.SaveAs',
    SAVE_ALL = 'Buttons.SaveALL',
    SAVE_FOR_BUM = 'Buttons.SaveForBUM',
    SAVE_FOR_ALL = 'Buttons.SaveForALL',
    SELECT = 'Buttons.Select',
    ACCEPT = 'Buttons.Accept',
    REJECT = 'Buttons.Reject',
    EDIT = 'Buttons.Edit',
    INSERT = 'Buttons.Insert',
    UPLOAD = 'Buttons.Upload',
    UPDATE_OPERATIONS = 'Buttons.UpdateOperations',
    ADD = 'Buttons.Save',
    SEARCH = 'Buttons.Search',
    SAVE_AS_DRAFT = 'Buttons.SaveAsDraft',
    UPDATE_DRAFT = 'Buttons.UpdateDraft',
    UPDATE = 'Buttons.Update',
    SAVE_FOR_ME = 'Buttons.SaveForMe',
    CREATE_SO = 'Buttons.CreateSO',
    CREATE_SO_DRAFT = 'Buttons.CreateSalesOrderDraft',
    UPDATE_SO_DRAFT = 'Buttons.UpdateSalesOrderDraft',
    ADD_ROLE = 'Buttons.AddRole',
    ADD_USER = 'Buttons.AddUser',
    REMOVE = 'Buttons.Remove',
    RESET = 'Buttons.Reset',
    COPY_TO_CLIPBOARD = 'Buttons.CopyToClipboard',
    COPIED_TO_CLIPBOARD = 'Buttons.CopiedToClipboard',
}

export enum SharedMsg {
    LOGOUT = 'Shared.Logout',
    CONTEXT_MENU = 'Shared.ContextMenu',
    COLUMN_CHOOSER = 'Shared.ColumnChooser',
    FILTERING = 'Shared.Filtering',
    PANEL = 'Shared.Panel',
    ON = 'Shared.On',
    OFF = 'Shared.Off',
    MASTER = 'Shared.Master',
    DETAILS = 'Shared.Details',
    SEARCH = 'Shared.Search',
    NO_DATA = 'Shared.NoData'
}

export enum MenuMsg {
    OPERATIONS = 'Menu.Operations',
    JOBS = 'Menu.Jobs',
    TASKS = 'Menu.Tasks',
    MAINTENANCE = 'Menu.Maintenance',
    INVENTORY = 'Menu.Inventory',
    STOCKS = 'Menu.Stocks',
    OUTGOING_ORDERS = 'Menu.OutgoingOrders',
    INGOING_ORDERS = 'Menu.IngoingOrders',
    LOCATIONS = 'Menu.Locations',
    MATERIALS = 'Menu.Materials',
    USER_MANAGEMENT = 'Menu.UserManagement',
    SUPPLIERS = 'Menu.Suppliers',
    NEWDRAFT = 'Menu.NewDraft',
    SAVED_DRAFT = 'Menu.SavedDrafts',
    DRAFT = 'Menu.Drafts',
    SALES_ORDER ='Menu.SalesOrder',
    SALES_ORDER_DRAFT ='Menu.SalesOrderDrafts'
}

export enum SettingsMsg {
    SETTINGS = 'Settings.Settings',
    COMPANY_INFORMATION = 'Settings.CompanyInformation',
    SUPPORT = 'Settings.Support',
    CAUSE_CODES = 'Settings.CauseCodes',
    USERS = 'Settings.Users',
    USER_SETTINGS = 'Settings.UserSettings',
    STOCKS_ATTRIBUTES = 'Settings.StockAttributes',
    ACTIVE_USERS = 'Settings.ActiveUsers',
    IMPORT_DATA = 'Settings.ImportData',
    INTEGRATION_MONITOR = 'Settings.IntegrationMonitor',
    INTEGRATION_IMPORTS = 'Settings.IntegrationImports',
    INTEGRATION_REQUESTS = 'Settings.IntegrationRequests',
    INTEGRATION_MESSAGES = 'Settings.IntegrationMessages',
    KPI_MANAGEMENT = 'Settings.KpiManagement',
    PRINTER_MANAGEMENT = 'Settings.PrinterManagement',
    DOWNLOADS = 'Settings.Downloads',
    LANGUAGE  = 'Settings.Language',
    VERSION = 'Settings.Version',
    TIMEZONE = 'Settings.Timezone',
    BUSINESS_UNIT = 'Settings.BusinessUnit',
    LOGOUT = 'Shared.Logout',
    ROLE = 'Settings.Role',
    USER = 'Settings.User',
    UPLOAD_PICTURE = 'Settings.UploadPicture',
    CHANGE_PASSWORD = 'Settings.ChangePassword',
    UPDATE_PHOTO = 'Settings.UpdatePhoto',
    NOT_IMPLEMENTED = 'Settings.NotImplemented'
}

export enum ContextMenuMsg {
    ASSIGN_TO_USER = 'ContextMenu.AssignToUser',
    SPLIT_ORDER = 'ContextMenu.SplitOrder',
    ASSIGN_TO_USER_ROLE = 'ContextMenu.AssignToUserRole',
    UNASSIGN = 'ContextMenu.Unassign',
    CHANGE_PRIORITY_DATE = 'ContextMenu.ChangePriorityDate',
    PREPARE = 'ContextMenu.Prepare',
    PREPARE_PARTLY = 'ContextMenu.PreparePartly',
    PREPARE_AND_WAIT = 'ContextMenu.PrepareAndWait',
    UNPREPARE = 'ContextMenu.Unprepare',
    CANCEL_ORDER_TASK = 'ContextMenu.CancelOrderTask',
    CANCEL_ORDER_PROCESS = 'ContextMenu.CancelOrderProcess',
    CREATE_AND_ASSIGN_TO_USER = 'ContextMenu.CreateAndAssignToUser',
    CREATE_AND_ASSIGN_TO_USER_ROLE = 'ContextMenu.CreateAndAssignToUserRole',
    CREATE_INVENTORY = 'ContextMenu.CreateInventory',
    ADJUST_PICKED_STOCKS = 'ContextMenu.AdjustPickedStocks',
    CANCEL = 'ContextMenu.Cancel',
    EDIT = 'ContextMenu.Edit',
    INSERT = 'ContextMenu.Insert',
    REMOVE = 'ContextMenu.Remove',
    Rest_Have_Been_Saved = 'ContextMenu.RestHaveBeenSaved',
    CLOSE = 'ContextMenu.Close',
    MOVE_ITEMS = 'ContextMenu.MoveItems',
    LOSS_ITEMS = 'ContextMenu.LossItems',
    GAIN_ITEM = 'ContextMenu.GainItem',
    EXPORT_SEARCH = 'ContextMenu.ExportSearch',
    EXPORT_ROWS = 'ContextMenu.ExportRows',
    LOOKUP_STOCKS = 'ContextMenu.LookupStocks',
    LOOKUP_MATERIALS = 'ContextMenu.LookupMaterials',
    LOOKUP_JOBS = 'ContextMenu.LookupJobs',
    LOOKUP_OPERATIONS = 'ContextMenu.LookupOperations',
    EXPORT_DATAGRID_ROWS='ContextMenu.ExportDataGridROWS',
    EXPORT_DATAGRID_ROW='ContextMenu.ExportDataGridROW',
    MASTER_TITLE = 'ContextMenu.MasterTitle',
    DETAIL_TITLE = 'ContextMenu.DetailTitle',
    EDIT_ORDERED_QUANTITY = 'ContextMenu.EditOrderedQuantity',
    VIEW_RESERVATIONS = 'ContextMenu.ViewReservations',
    CANCEL_TASK = 'ContextMenu.CancelTask',
    ADD_LOCATION = 'ContextMenu.AddLocation',
    ADD_USER = 'ContextMenu.AddUser',
    DELETE_LOCATION = 'ContextMenu.DeleteLocation',
    UPDATE_LOCATION = 'ContextMenu.UpdateLocation',
    COMPLETE_ORDER = 'ContextMenu.CompleteOrder',
    CREATE_STOCK = 'ContextMenu.CreateStock',
    ADD_PRINTER = 'ContextMenu.AddPrinter',
    ADD_STOCK_ATTRIBUTE = 'ContextMenu.AddStockAttribute',
    EDIT_MIN_MAX = 'ContextMenu.EditMinMax',
    DEDICATE_LOCATION_TO_MATERIAL = 'ContextMenu.DedicateLocationToMaterial',
    REMOVE_DEDICATED_LOCATION = 'ContextMenu.RemoveDedicatedLocation',
    CREATE_SUPPLIER = 'ContextMenu.CreateSupplier',
    UPDATE_SUPPLIER = 'ContextMenu.UpdateSupplier',
    DELETE_SUPPLIER = 'ContextMenu.DeleteSupplier',
    REMOVE_SUPPLIER_PRODUCT = 'ContextMenu.RemoveFromSupplier',
    ADD_PRODUCT_TO_SUPPLIER = 'ContextMenu.AddProductToSupplier',
    SAVE_DRAFT = 'ContextMenu.SaveDraft',
    NEW_DRAFT = 'ContextMenu.NewDraft',
    UPDATE = 'ContextMenu.Update',
    DELETE = 'ContextMenu.Delete',
    DOWNLOAD = 'ContextMenu.Download',
    CREATE_PO = 'ContextMenu.CreatePO',
    REMOVE_MATERIAL = 'ContextMenu.RemoveMaterial',
    DRAFT_NO_ALEADY_EXIST = 'ContextMenu.DraftNoAlreadyExists',
    ORDER_NO_ALEADY_EXISTS = 'ContextMenu.OrderNoAlreadyExists',
    SAVE_DRAFT_ERROR_MSG = 'ContextMenu.SaveDraftErrorMsg',
    ORDER_NO_ALREADY_EXIST = 'ContextMenu.OrderNoAlreadyExist',
    PO_ERROR_MSG = 'ContextMenu.POErrorMsg',
    EDIT_MATERIAL = 'ContextMenu.EditMaterial',
    NAVIGATE_TO_REQUESTS = 'ContextMenu.NavigateToRequests',
    NAVIGATE_TO_MESSAGES = 'ContextMenu.NavigateToMessages',
    CREATE_SALES_ORDER = 'ContextMenu.CreateSalesOrder',
    SAVE_AS_SO_DRAFT = 'ContextMenu.SaveAsSODraft',
    SAVE_AS_SO = 'ContextMenu.SaveAsSO',
    EDIT_SALES_ORDER_DRAFT = 'ContextMenu.EditSalesOrderDraft',
    DELETE_SALES_ORDER_DRAFT = 'ContextMenu.DeleteSalesOrderDraft',
    UPDATE_AS_SO_DRAFT = 'ContextMenu.UpdateAsSODraft',
    UPDATE_DELIVERY_PRIORITY_DATE = 'ContextMenu.UpdateDeliveryPriorityDate',
    DEACTIVATE = 'ContextMenu.Deactivate',
    ACTIVATE = 'ContextMenu.Activate',
    RESET_PASSWORD = 'ContextMenu.ResetPassword'
}

export enum FilterMsg {
    AUTO_REFRESH = 'Filters.AutoRefresh',
    FROM_DATE = 'Filters.FromDate',
    TO_DATE = 'Filters.ToDate',
    HISTORICAL_DATA = 'Filters.HistoricalData',
    HISTORICAL_DATA_HINT = 'Filters.HistoricalDataHint',
    LOCATION = 'Filters.Location',
    LOCATION_ROLE = 'Filters.LocationRole',
    FROM_LOCATION = 'Filters.FromLocation',
    TO_LOCATION = 'Filters.ToLocation',
    FROM_LOCATION_ROLE = 'Filters.FromLocationRole',
    TO_LOCATION_ROLE = 'Filters.ToLocationRole',
    FROM_LOCATION_ROLE_GROUP = 'Filters.FromLocationRoleGroup',
    TO_LOCATION_ROLE_GROUP = 'Filters.ToLocationRoleGroup',
    ITEM_NUMBER = 'Filters.ItemNumber',
    MATERIAL_IDENTIFIER = 'Filters.MaterialIdentifier',
    MATERIAL_NAME = 'Filters.MaterialName',
    BAR_CODE = 'Filters.BarCode',
    SELECT_ALL = 'Filters.SelectAll',
    OPTIMIZATION_TYPE = 'Filters.OptimizationType',
    ORDER_NUMBER = 'Filters.OrderNumber',
    INTEGRATION_TYPE = 'Filters.IntegrationType',
    DIRECTION = 'Filters.Directions',
    INTEGRATION_STATE = 'Filters.IntegrationState',
    COMMAND_STATE = 'Filters.CommandState',
    COMMAND_ERROR_STATE = 'Filters.CommandErrorState',
    SIGNAL_R = 'Filters.SignalR',
    MATERIALS_ON_STOCK= 'Filters.ShowMaterialsOnStock',
    SEARCH_PHRASE = 'Filters.SearchPhrase',
    EXTERNAL_SYSTEM_NAMES = 'Filters.ExternalSystemNames',
    MAIN_RETRIEVAL_ENDPOINT_NAMES = 'Filters.MainRetrievalEndpointNames',
    RESULT_LEVEL = 'Filters.ResultLevelFilter',
    INITIATED_BY = 'Filters.InitiatedBy',
    URL = 'Filters.Url',
    INTEGRATION_MESSAGE_ID = 'Filters.IntegrationMessageId',
    MESSAGE_TYPE = 'Filters.MessageType',
    MESSAGE_DIRECTION = 'Filters.MessageDirection',
    KEY_TYPE = 'Filters.KeyType',
    KEY_VALUE = 'Filters.KeyValue',
    EVENT_SUBSCRIPTION_NAMES = 'Filters.EventSubscriptionNames',
    FILTER = 'Filters.Filter',
    SORT = 'Filters.Sort',
    SORT_ORDER = 'Filters.SortOrder',
    GROUP = "Filters.Group",
    COLUMNS = "Filters.Columns",
    VIEW = 'Filters.StandardView.View',
    CHOOSE_VIEW = 'Filters.StandardView.ChooseView',
    CHANGE_TO_STANDARD_VIEW = 'Filters.StandardView.ChangeToStandardView',
    SAVE_CURRENT_VIEW = 'Filters.StandardView.saveCurrentView',
    CURRENT_VIEW_TEXT = 'Filters.StandardView.currentViewText',
    APPLY = 'Filters.Apply',
    RESET = 'Filters.Reset', 
    GROUP_BY_COLUMN = 'Filters.GroupByColumn', 
    NO_DATA_FOUND = 'Filters.NoDataFound',
    DEFAULT = 'Filters.Default'
}

export enum WarningMsg {
    TITLE = 'Dialogs.Warnings.Title',
    ORDER_STARTED = 'Dialogs.Warnings.OrderStarted',
    ORDER_CANCELLED = 'Dialogs.Warnings.OrderCancelled',
    NO_ITEM_SELECTED = 'Dialogs.Warnings.NoItemSelected',
    NO_TASK_OR_USER = 'Dialogs.Warnings.NoTaskOrUser',
    NO_TASK_OR_USER_ROLE = 'Dialogs.Warnings.NoTaskOrUserRole',
    NO_STOCKS_TO_UPDATE = 'Dialogs.Warnings.NoStocksToUpdate',
    NO_ROWS_SELECTED = 'Dialogs.Warnings.NoRowsSelected',
    MUST_BE_VALID_QUANTITY= 'Dialogs.Warnings.MustBeValidQty',
    ATLEAST_ONE_MATERIAL='Dialogs.Warnings.AtleastOneMaterial',
    UPDATE_VIEW_TEXT = 'Dialogs.Warnings.UpdateViewText',
    UPDATE_VIEW_TITLE = 'Dialogs.Warnings.UpdateViewTitle'
   
}

export enum CustomViewMsg {
    TITLE = 'Title',
    MESSAGE = 'Message',
    PLACEHOLDER = 'Placeholder',
}

export enum SignalRMsg {
    TITLE = 'Dialogs.SignalR.Title',
    LOGOUT = 'Shared.Logout',
    CONTINUE = 'Dialogs.SignalR.ContinueBtn',
    CONNECTION_LOST = 'Dialogs.SignalR.ConnectionLost',
    CONNECTION_FAILED = 'Dialogs.SignalR.ConnectionFailed',
}

export enum DxConfigMsg {
    SEARCH = 'Shared.Search',
    MASTER_TITLE = 'ContextMenu.MasterTitle',
    DETAIL_TITLE = 'ContextMenu.DetailTitle',
    COLUMN_CHOOSER = 'ContextMenu.ColumnChooser',
    ADAPT_GRID = 'ContextMenu.AdaptGrid',
    FILTERING = 'ContextMenu.Filtering',
    PANEL = 'ContextMenu.Panel',
    SAVE = 'Buttons.Save',
    SAVE_AS = 'Buttons.SaveAs',
    DELETE_CW = 'Buttons.DeleteCustomView',
    EXPORT_SEARCH = 'ContextMenu.ExportSearch',
    EXPORT_ROWS = 'ContextMenu.ExportRows',
    EXPORT_DATA = 'ContextMenu.ExportData',
    EXPORT_DETAILS = 'ContextMenu.ExportDetails',
    CUSTOM_VIEWS = 'ContextMenu.CustomViews',
    GRID_SEARCH = 'ContextMenu.GridSearch',
    EXPORT = 'ContextMenu.Export',
    GRID_OPTION = 'ContextMenu.GridOptions'
}

export enum UserSettingsMsg {
    SET_DEFAULT = 'UserSettings.SetDefaultButton',

    MENU_ITEM_CHECKBOX = 'UserSettings.MenuItem.CheckboxLabel',
    MENU_ITEM_INPUT = 'UserSettings.MenuItem.InputTitle',

    COCKPIT_TITLE = 'UserSettings.Cockpit.Title',
    COCKPIT_SECTION_SETTINGS = 'UserSettings.Cockpit.Sections.Settings',
    COCKPIT_SECTION_MENU = 'UserSettings.Cockpit.Sections.Menu',
    COCKPIT_SECTION_PERMISSIONS = 'UserSettings.Cockpit.Sections.Permissions',
    COCKPIT_SECTION_TRANSLATIONS = 'UserSettings.Cockpit.Sections.Translations',

    COCKPIT_PERMISSIONS_ALL = 'UserSettings.Cockpit.Permissions.AllLabel',
    COCKPIT_PERMISSIONS_BTN = 'UserSettings.Cockpit.Permissions.ButtonText',

    COCKPIT_SETTINGS_DISPLAYNAME = 'UserSettings.Cockpit.Settings.DisplayName',
    COCKPIT_SETTINGS_HEARTBEAT = 'UserSettings.Cockpit.Settings.HeartBeat',
    COCKPIT_SETTINGS_ROWSLIMIT = 'UserSettings.Cockpit.Settings.RowsLimit',

    TERMINAL_TITLE = 'UserSettings.Terminal.Title',
    TERMINAL_SECTION_APPCENTER = 'UserSettings.Terminal.Sections.AppCenter',
    TERMINAL_SECTION_SETTINGS = 'UserSettings.Terminal.Sections.Settings',
    TERMINAL_SECTION_MENU = 'UserSettings.Terminal.Sections.Menu',
    TERMINAL_SECTION_CONTEXTMENU = 'UserSettings.Terminal.Sections.ContextMenu',
    TERMINAL_SECTION_TRANSLATIONS = 'UserSettings.Terminal.Sections.Translations',

    TERMINAL_UPDATES_FORCE = 'UserSettings.Terminal.Updates.Force',
    TERMINAL_UPDATES_APPCENTERNAME = 'UserSettings.Terminal.Updates.AppCenterName',
    TERMINAL_UPDATES_APPAPITOKEN = 'UserSettings.Terminal.Updates.AppApiToken',
    TERMINAL_UPDATES_LOWESTRELEASE = 'UserSettings.Terminal.Updates.LowestRelease',
    TERMINAL_UPDATES_RELEASEID = 'UserSettings.Terminal.Updates.ReleaseId',

    TERMINAL_SETTINGS_HEARTBEAT = 'UserSettings.Terminal.Settings.HeartBeat',
    TERMINAL_SETTINGS_DISPLAYTIME = 'UserSettings.Terminal.Settings.DisplayTime',
    TERMINAL_SETTINGS_SCAN_QUANTITY = 'UserSettings.Terminal.Settings.ScanQuantity',
    TERMINAL_SETTINGS_SCAN_UOM = 'UserSettings.Terminal.Settings.ScanUoM',
    TERMINAL_SETTINGS_AUTO_CONFIRM = 'UserSettings.Terminal.Settings.AutoConfirm',
    TERMINAL_SETTINGS_LOOPED_GAIN = 'UserSettings.Terminal.Settings.LoopedGain',
    TERMINAL_SETTINGS_TIMEOUT = 'UserSettings.Terminal.Settings.Timeout',
    TERMINAL_SETTINGS_PICKLABEL_PRINTER = 'UserSettings.Terminal.Settings.CanSetPickLabelPrinter',
    TERMINAL_GS1_STRIP_LEADING_0 = 'UserSettings.Terminal.Settings.GS1StripLeadingZeroInEan',

    SYSTEM_TITLE = 'UserSettings.System.Title',
    SYSTEM_SETTINGS_IP_WHITELIST = 'UserSettings.System.Settings.IpWhitelist',
    SYSTEM_SETTINGS__WHITELIST_NAME = 'UserSettings.System.Settings.WhitelistName',
    SYSTEM_SETTINGS_WHITELIST_IP = 'UserSettings.System.Settings.WhitelistIp',
    SYSTEM_SETTINGS_CODESWITCHES = 'UserSettings.System.Settings.CodeSwitches',
    SYSTEM_SETTINGS_FEATUREFLAGS = 'UserSettings.System.Settings.FeatureFlags',
    SYSTEM_SETTINGS_IMPORT_MESSAGE = 'UserSettings.Cockpit.Settings.ImportMessage',
}

export enum KpiT9Keys {
    ORDERS_ORDER_LINES = 'Kpi.Orders.OrderLines',
    ORDERS_TASKS = 'Kpi.Orders.Tasks',

    INBOUND_TITLE = 'Kpi.InboundOrders.Title',
    INBOUND_IMG_SRC = 'Kpi.InboundOrders.ImgSrc',

    OUTBOUND_TITLE = 'Kpi.OutboundOrders.Title',
    OUTBOUND_IMG_SRC = 'Kpi.OutboundOrders.ImgSrc',

    LOCATION_TITLE = 'Kpi.Location.Title',
    LOCATION_COLUMNS_ROLE = 'Kpi.Location.Columns.LocationRole',
    LOCATION_COLUMNS_PREPARED = 'Kpi.Location.Columns.Prepared',
    LOCATION_COLUMNS_PERFORMED = 'Kpi.Location.Columns.Performed',
    LOCATION_COLUMNS_INPROGRESS = 'Kpi.Location.Columns.InProgress',
}

export enum TabMenuMsg {
    ORDER_INFO = 'CreateSalesOrder.orderInfo',
    MATERIALS = 'CreateSalesOrder.materials'
}
